import React from "react"
import { useTranslation } from "react-i18next"
import { LogIn } from "@app/assets/Icons"
import { useAuth0 } from "@auth0/auth0-react"
import cn from "classnames"

const LoginButton = (): JSX.Element => {
  const { t } = useTranslation()
  const { loginWithRedirect } = useAuth0()

  const buttonClassName =
    "flex w-30 items-center justify-start button-zinc-800 border-none space-x-2 px-6"

  return (
    <button
      className={cn(buttonClassName, "rounded-3xl p-4 cursor-pointer")}
      type="button"
      onClick={async () => loginWithRedirect()}
    >
      <LogIn className="size-8" />
      <span className="text-base font-medium">
        {t("header.profile.dropdown.log_in")}
      </span>
    </button>
  )
}

export default LoginButton

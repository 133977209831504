import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useUploadQuery } from "@app/api/files/useUploadQuery"
import { ArrowUpTrayIcon, CheckCircleIcon, Upload } from "@app/assets/Icons"
import { useChatbotContext } from "@app/context/ChatbotContext"

import Progress from "./Progress"

const supportedFileFormats =
  ".txt, .md, .html, .doc, .docx, .csv, .xls, .xlsx, .pdf"

const UploadFile = (): JSX.Element => {
  const [progress, setProgress] = useState<number>(0)
  const { t } = useTranslation()
  const { chatbotName } = useChatbotContext()

  const { isPending, mutateAsync, data } = useUploadQuery(chatbotName)

  useEffect(() => {
    if (isPending) {
      setProgress(0)

      const interval = setInterval(() => {
        setProgress(prevProgress => {
          if (prevProgress >= 95) {
            clearInterval(interval)
            return prevProgress
          }
          return prevProgress + 5
        })
      }, 100)

      return () => {
        clearInterval(interval)
      }
    }
    return
  }, [isPending])

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event.target.files) {
      try {
        await mutateAsync(event.target.files)
      } catch (error) {
        console.warn(error)
      }
    }
  }

  return (
    <>
      <label>
        <input
          type="file"
          accept={supportedFileFormats}
          hidden
          multiple
          onChange={handleFileChange}
        />
        <div
          className="flex h-16 cursor-pointer items-center justify-between rounded-full bg-white px-6 font-normal text-grey-900
                                                     shadow focus:outline-none sm:text-xs md:text-sm lg:text-lg"
        >
          <span className="mr-2 text-grey-900">{t("uploadFile.button")}</span>
          <ArrowUpTrayIcon className="w-6 text-black" aria-hidden="true" />
        </div>
      </label>

      {isPending && (
        <div className="flex flex-col items-center justify-center mt-6">
          <Upload className="h-8 w-8 text-grey-200" />
          <p> {t("uploadFile.uploading")}</p>
          <div className="h-2.5 w-full rounded-full bg-white">
            <Progress progress={progress} />
          </div>
        </div>
      )}

      {Boolean(data?.files.length) && (
        <div className="flex flex-col items-center justify-center mt-6">
          <CheckCircleIcon
            className="text-green-50 h-8 w-8"
            aria-hidden="true"
          />
          <p>{t("uploadFile.uploaded")}</p>

          <div className="h-2.5 w-full rounded-full bg-white">
            <Progress progress={100} />
          </div>
        </div>
      )}
    </>
  )
}

export default UploadFile

export enum DateFilter {
  ThisYear = "1y",
  Last30Days = "30d",
  Last7Days = "7d",
  Last24Hours = "24h",
}

export const allFilters: DateFilter[] = [
  DateFilter.ThisYear,
  DateFilter.Last30Days,
  DateFilter.Last7Days,
  DateFilter.Last24Hours,
]

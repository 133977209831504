import { useEffect, useState } from "react"

export type BreakpointSizes = "sm" | "md" | "lg" | "xl"

export const breakpoints = {
  sm: "600px",
  md: "960px",
  lg: "1280px",
  xl: "1920px",
}

export const breakpointsNumber = {
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
}

type MediaQueryReturnType = {
  isMobileView: boolean
}
export const useIsMobileView = (
  breakpoint: BreakpointSizes = "md",
): MediaQueryReturnType => {
  const [isDesktopView, setIsDesktopView] = useState(false)

  const isMobileView = !isDesktopView

  useEffect(() => {
    const query = `(min-width: ${breakpoints[breakpoint]})`
    const media = window.matchMedia(query)
    if (media.matches !== isDesktopView) {
      setIsDesktopView(!isDesktopView)
    }
    const listener = (): void => setIsDesktopView(media.matches)
    window.addEventListener("resize", listener)
    return (): void => window.removeEventListener("resize", listener)
  }, [isDesktopView, breakpoint])

  return { isMobileView }
}

export default useIsMobileView

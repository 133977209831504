import { useTranslation } from "react-i18next"
import { Alert } from "@mui/material"

const PageNotFound = (): JSX.Element => {
  const { t } = useTranslation()

  return <Alert severity="error">{t("common.page_not_found")}</Alert>
}

export default PageNotFound

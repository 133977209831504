import { queryClient } from "@app/App/queryClient"
import * as S from "@effect/schema/Schema"
import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"
import { pipe } from "effect"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedMutation } from "../useAuthenticatedMutation"

export type UploadFile = {
  readonly key: string
}

type UploadFileResponseAPI = {
  readonly files: readonly UploadFile[]
}

const UploadFileSchema: S.Schema<UploadFile> = S.Struct({
  key: S.String,
})

const UploadFileResponseAPISchema: S.Schema<UploadFileResponseAPI> = S.Struct({
  files: S.Array(UploadFileSchema),
})

const toFormData = (fileList: FileList): FormData => {
  const formData = new FormData()

  for (const file of fileList) {
    if (file) {
      formData.append("files", file, file.name)
    }
  }

  return formData
}

const uploadFile = async (
  chatbotName: string,
  files: FileList,
  token: string,
): Promise<UploadFileResponseAPI> => {
  const apiFiles = toFormData(files)
  const filesPath = `${REST_URL}${API_V1}/files/${chatbotName}`
  const response = await fetch(filesPath, {
    method: "POST",
    body: apiFiles,
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw new Error("File upload failed")
  }

  const results = (await response.json()) as unknown

  return pipe(results, S.decodeUnknownSync(UploadFileResponseAPISchema))
}

export const useUploadQuery = (
  chatbotName: string,
  options?: UseMutationOptions<UploadFileResponseAPI, Error, FileList>,
): UseMutationResult<UploadFileResponseAPI, Error, FileList> => {
  return useAuthenticatedMutation<UploadFileResponseAPI, Error, FileList>(
    async (files, token) => uploadFile(chatbotName, files, token),
    {
      ...options,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: QueryKey.fileList })
      },
    },
  )
}

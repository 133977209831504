import { ReactNode } from "react"
import cn from "classnames"

type PageWrapperProps = {
  className?: string
  title?: string
  dataCy?: string
  children: ReactNode
}
const PageWrapper = ({
  children,
  className,
  title,
  dataCy,
}: PageWrapperProps): JSX.Element => {
  const testTag = dataCy ?? `page-wrapper-title-${title}`

  return (
    <div
      className={cn("p-10 bg-1 flex h-full flex-col flex-nowrap", className)}
    >
      {title && (
        <div className="mb-3 space-y-0.5">
          <h1 className="header-2xl" data-cy={testTag}>
            {title}
          </h1>

          <div className="h-divider" />
        </div>
      )}

      <div className="grow">{children}</div>
    </div>
  )
}

export default PageWrapper

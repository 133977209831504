import React from "react"
import { useTranslation } from "react-i18next"
import { allFilters, DateFilter } from "@app/model/dateFilter"
import cn from "classnames"

type FilterState = {
  title: string
  timeframe: string
}

type FilterProps = {
  selectedTimeFrame: DateFilter
  setSelectedTimeFrame: (timeFrame: DateFilter) => void
}

const Filters = (props: FilterProps): JSX.Element => {
  const { selectedTimeFrame, setSelectedTimeFrame } = props

  const { t } = useTranslation()

  const toUIKitFilter = (filter: DateFilter): FilterState => {
    switch (filter) {
      case DateFilter.ThisYear:
        return {
          title: t("date_filter.this_year"),
          timeframe: DateFilter.ThisYear,
        }
      case DateFilter.Last30Days:
        return {
          title: t("date_filter.last_30_days"),
          timeframe: DateFilter.Last30Days,
        }
      case DateFilter.Last7Days:
        return {
          title: t("date_filter.last_7_days"),
          timeframe: DateFilter.Last7Days,
        }
      case DateFilter.Last24Hours:
        return {
          title: t("date_filter.last_24_hours"),
          timeframe: DateFilter.Last24Hours,
        }
    }
  }

  return (
    <div className="max-w-[376px] sm:ml-auto lg:m-auto rounded-[60px] bg-grey-50 py-4">
      {allFilters.map(timeframe => {
        const filter = toUIKitFilter(timeframe)
        return (
          <button
            type="button"
            key={filter.timeframe}
            className={cn(
              "border-none rounded-[64px] px-3 py-[9px] text-grey-900 text-xs cursor-pointer",
              {
                "bg-grey-300": timeframe === selectedTimeFrame,
                "bg-transparent": timeframe !== selectedTimeFrame,
              },
            )}
            onClick={() => setSelectedTimeFrame(timeframe)}
          >
            {filter.title}
          </button>
        )
      })}
    </div>
  )
}

export default Filters

import { Modal as MaterialModal } from "@mui/material"

type ModalProps = {
  open: boolean
  children: React.ReactNode
  onClose: () => void
}

const Modal = ({ open, children, onClose }: ModalProps): JSX.Element => {
  return (
    <MaterialModal
      open={open}
      onClose={onClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      className="bg-[rgba(27,31,42,0.8)]"
    >
      <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[455px] bg-gradient-to-r from-[#D5E8E1] to-[#B1E1D5] rounded-[30px] p-4 outline-none">
        {children}
      </div>
    </MaterialModal>
  )
}

export default Modal

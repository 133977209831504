import { downloadFileFromBlob } from "@app/services/downloadFile"
import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { useAuthenticatedMutation } from "../useAuthenticatedMutation"

export type Key = string

const downloadFile = async (
  chatbotName: string,
  key: Key,
  token: string,
): Promise<void> => {
  const filesPath = `${REST_URL}${API_V1}/files/${chatbotName}/download?key=${key}`
  const response = await fetch(filesPath, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw new Error("File download failed")
  }

  const blob = await response.blob()

  downloadFileFromBlob({ blob, fileName: key })
}

export const useDownloadQuery = (
  chatbotName: string,
  options?: UseMutationOptions<void, Error, Key>,
): UseMutationResult<void, Error, Key> => {
  return useAuthenticatedMutation<void, Error, Key>(
    async (key, token) => downloadFile(chatbotName, key, token),
    options,
  )
}

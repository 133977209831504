import React, { createContext, ReactNode, useContext, useState } from "react"

const ChatbotContext = createContext<
  { chatbotName: string; setChatbotName: (path: string) => void } | undefined
>(undefined)

const ChatbotProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const [chatbotName, setChatbotName] = useState<string>("")

  return (
    <ChatbotContext.Provider value={{ chatbotName, setChatbotName }}>
      {children}
    </ChatbotContext.Provider>
  )
}

const useChatbotContext = (): {
  chatbotName: string
  setChatbotName: (chatbot: string) => void
} => {
  const context = useContext(ChatbotContext)
  if (!context) {
    throw new Error("useChatbotContext must be used within an ChatbotProvider")
  }
  return context
}

export { ChatbotProvider, useChatbotContext }

import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useChatbotContext } from "@app/context/ChatbotContext"
import { AuthUser } from "@app/model/auth"
import { ROLE_NAMESPACE } from "@app/model/constants"
import { convertToTitleCase } from "@app/services/data"
import { useAuth0 } from "@auth0/auth0-react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material"

const ChatbotDropdown = (): JSX.Element => {
  const { t } = useTranslation()
  const { user } = useAuth0<AuthUser>()
  const { chatbotName, setChatbotName } = useChatbotContext()

  const roles = user?.[ROLE_NAMESPACE]

  useEffect(() => {
    if (roles?.length) {
      setChatbotName(roles[0] ?? "")
    }
  }, [roles, setChatbotName])

  const handleChange = (event: SelectChangeEvent): void => {
    setChatbotName(event.target.value)
  }

  return (
    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
      <InputLabel id="demo-select-small-label">
        {t("common.chatbot")}
      </InputLabel>
      <Select
        labelId="demo-simple-select-standard-label"
        id="demo-simple-select-standard"
        value={chatbotName}
        onChange={handleChange}
        label="Chatbot"
      >
        {roles?.map((role: string) => {
          return (
            <MenuItem key={role} value={role}>
              {convertToTitleCase(role)}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

export default ChatbotDropdown

import { useAuth0 } from "@auth0/auth0-react"
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query"

export const useAuthenticatedMutation = <
  TData,
  TError = Error,
  TVariables = unknown,
>(
  mutateFn: (data: TVariables, token: string) => Promise<TData>,
  options?: UseMutationOptions<TData, TError, TVariables>,
): UseMutationResult<TData, TError, TVariables> => {
  const { getAccessTokenSilently } = useAuth0()

  const authMutateFn = async (data: TVariables): Promise<TData> => {
    const token = await getAccessTokenSilently()
    return mutateFn(data, token)
  }

  return useMutation<TData, TError, TVariables>({
    mutationFn: authMutateFn,
    ...options,
  })
}

import React from "react"
import { useTranslation } from "react-i18next"
import { Link, useLocation } from "react-router-dom"
import { ChevronLeft, Plane } from "@app/assets/Icons"
import { OutlinedInput } from "@mui/material"
import cn from "classnames"

type LocationState = {
  query?: string
}

const AskAiPanel = (): JSX.Element => {
  const { t } = useTranslation()
  const location = useLocation()

  const queryValue = (location.state as LocationState)?.query

  return (
    <div
      className={cn(
        "flex flex-row rounded-3xl text-center sm:col-span-7 md:col-span-3 bg-white border-2 border-grey-900 border-solid",
      )}
    >
      <div className={cn("flex flex-1 p-6 flex-col")}>
        <Link to="/dashboard" className="no-underline text-grey-900">
          <div className="flex items-center cursor-pointer">
            <ChevronLeft className="mr-4" />
            <span className="text-2xl font-semibold">
              {t("ask_ai.back_to_all_questions")}
            </span>
          </div>
        </Link>
        <div className="flex flex-1 items-center text-left p-10">
          <span className="text-grey-300">{t("ask_ai.info")}</span>
        </div>
      </div>
      <div className="flex flex-1 text-grey-900 flex-col p-6 w-full bg-grey-50 rounded-3xl border-2 border-grey-900 border-solid relative top-[-2px] right-[-2px] h-[calc(100%+4px)]">
        <div className="flex flex-1 flex-col text-left">
          {queryValue && (
            <>
              <div>
                <p className="text-sm text-grey-200">
                  {t("common.user_question")}
                </p>
                <div className="w-fit bg-white p-4 rounded-tl-3xl rounded-tr-3xl rounded-br-3xl">
                  {queryValue}
                </div>
              </div>

              <div className="ml-auto">
                <div className="flex justify-between items-center">
                  <p className="text-sm text-grey-200">
                    {t("common.bot_answer")}
                  </p>
                </div>

                <div className="w-fit bg-grey-900 p-4 rounded-tl-3xl rounded-tr-3xl rounded-bl-3xl text-white">
                  some answer
                </div>
              </div>
            </>
          )}
          <div className="flex justify-center items-center mt-auto">
            <OutlinedInput
              sx={{
                fontSize: "20px",
                borderRadius: "60px",
                border: "2px solid #24272F",
                height: "60px",
                width: "100%",
              }}
              placeholder={t("ask_ai.placeholder")}
              className="mr-4"
            />
            <button
              className={cn(
                "flex justify-center items-center bg-grey-50 rounded-[50%] w-[60px] h-[60px] border-2 p-4 cursor-pointer",
              )}
              type="button"
              onClick={() => {}}
            >
              <Plane />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AskAiPanel

import React from "react"
import { useTranslation } from "react-i18next"
import { LogOut } from "@app/assets/Icons"
import { useAuth0 } from "@auth0/auth0-react"
import cn from "classnames"

const LogoutButton = (): JSX.Element => {
  const { t } = useTranslation()
  const { logout } = useAuth0()

  const buttonClassName =
    "flex w-52 items-center justify-start button-zinc-800 border-none space-x-2 px-6"

  return (
    <button
      className={cn(buttonClassName, "rounded-3xl p-4 cursor-pointer")}
      type="button"
      onClick={async () =>
        logout({ logoutParams: { returnTo: window.location.origin } })
      }
    >
      <LogOut />
      <span className="text-base font-medium">
        {t("header.profile.dropdown.log_out")}
      </span>
    </button>
  )
}

export default LogoutButton

import {
  Question,
  QuestionsParams,
  QuestionsResponseAPI,
  Rating,
} from "@app/model/questions"
import * as S from "@effect/schema/Schema"
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import { pipe } from "effect"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey" // Ensure this exports a type, not just a constant
import { useAuthenticatedQuery } from "../useAuthenticatedQuery"

type QueryResultData = QuestionsResponseAPI
type QueryKeyType = ReturnType<typeof QueryKey.questionsList>

const RatingSchema: S.Schema<Rating> = S.Struct({
  vote: S.String,
  comment: S.String,
})

const QuestionsSchema: S.Schema<Question> = S.Struct({
  id: S.String,
  question: S.String,
  userEmail: S.String,
  userName: S.String,
  createdAt: S.Number,
  answerId: S.NullOr(S.String),
  answer: S.NullOr(S.String),
  rating: S.NullOr(RatingSchema),
})

const QuestionsResponseAPISchema: S.Schema<QuestionsResponseAPI> = S.Struct({
  questions: S.Array(QuestionsSchema),
  page: S.Number,
  pageSize: S.Number,
  totalCount: S.Number,
})

export const getQuestionList: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey, meta }): Promise<QueryResultData> => {
  const [_, chatbotName, params] = queryKey
  const { page, page_size, timeframe, sort_by, sort_order } = params

  const paramsString = new URLSearchParams({
    page,
    page_size,
    timeframe,
    sort_by,
    sort_order,
    role_id: chatbotName,
  }).toString()

  const questionsPath = `${REST_URL}${API_V1}/questions/?${paramsString}`

  const token = meta?.token as string

  const response = await fetch(questionsPath, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw Error("Get questions fails")
  }

  const results = (await response.json()) as unknown

  return pipe(results, S.decodeUnknownSync(QuestionsResponseAPISchema))
}

export const useQuestionsQuery = (
  chatbotName: string,
  params: QuestionsParams,
  options?: Omit<
    UseQueryOptions<QueryResultData, Error, QueryResultData, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<QueryResultData, Error> => {
  const queryKey = QueryKey.questionsList(chatbotName, params)

  return useAuthenticatedQuery<QueryResultData, Error, QueryKeyType>(
    queryKey,
    getQuestionList,
    options,
  )
}

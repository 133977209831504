import * as Sentry from "@sentry/react"

export const init = (): void => {
  Sentry.init({
    dsn: "https://73091f350f36b796ee6f2b2a53557a2b@o1030063.ingest.us.sentry.io/4507866157285376",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    beforeSend: (event, hint) => {
      if (process.env.ENABLE_SENTRY === "false") {
        console.log(hint?.originalException || hint?.syntheticException)
        console.log(event)
        return null
      } else {
        return event
      }
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
type Exception = string | Error | unknown
export const captureException = (exception: Exception): void => {
  Sentry.captureException(exception)
}

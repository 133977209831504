type DownloadFileFromBlobParams = {
  blob: Blob
  fileName: string
}

export const downloadFileFromBlob = ({
  blob,
  fileName,
}: DownloadFileFromBlobParams): void => {
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob)
  link.download = fileName
  link.click()
}

import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { DownArrow, UpArrow } from "@app/assets/Icons"
import LoginButton from "@app/uiKit/LoginButton"
import { useAuth0 } from "@auth0/auth0-react"
import { Backdrop } from "@mui/material"
import { Option, pipe, String } from "effect"

import ProfileDropdown from "./ProfileDropdown"

const ProfileHeader = (): JSX.Element => {
  const { user, isAuthenticated } = useAuth0()
  const [isDropdownOpen, setDropdownState] = useState(false)
  const { t } = useTranslation()
  const buttonRef = useRef<HTMLButtonElement>(null)

  const firstName = user?.given_name || "Iron"
  const lastName = user?.family_name || "Man"
  const firstInitial = pipe(
    firstName,
    String.at(0),
    Option.map(String.toUpperCase),
    Option.getOrElse(() => "?"),
  )
  const lastInitial = pipe(
    lastName,
    String.at(0),
    Option.map(String.toUpperCase),
    Option.getOrElse(() => "?"),
  )
  const displayName = `${firstName} ${lastName}`

  const handleSetDropdownState = (): void => {
    setDropdownState(!isDropdownOpen)
  }

  const closeDropdown = (): void => {
    setDropdownState(false)
  }

  const buttonClassName =
    "flex flex-row items-center justify-center bg-white border-none cursor-pointer p-0"
  const initialsClassName =
    "flex items-center justify-center h-14 w-14 rounded-full bg-zinc-800 bdr-teal-800"

  return (
    <div>
      {isAuthenticated ? (
        <button
          className={buttonClassName}
          type="button"
          ref={buttonRef}
          data-testid="profile-button"
          onClick={handleSetDropdownState}
        >
          <div className="pr-4 hidden sm:block">
            <span className="text-xl">
              {`${t("header.profile.hi")}, `}
              <span className="font-bold">{displayName}</span>
            </span>
          </div>
          <div className="sm:pr-2 lg:pr-2">
            <div className={initialsClassName}>
              <span className="text-xl font-medium text-white">
                {firstInitial}
                {lastInitial}
              </span>
            </div>
          </div>
          <div>{isDropdownOpen ? <UpArrow /> : <DownArrow />}</div>
        </button>
      ) : (
        <LoginButton />
      )}

      {isDropdownOpen && (
        <Backdrop
          open={isDropdownOpen}
          sx={{
            color: "#24272F",
            zIndex: "1",
            backdropFilter: "blur(2px)",
            backgroundColor: "#0000004d",
          }}
        >
          <ProfileDropdown close={closeDropdown} buttonRef={buttonRef} />
        </Backdrop>
      )}
    </div>
  )
}

export default ProfileHeader

export function convertToTitleCase(value: string): string {
  return value
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
}

export function getPaginationPages(
  totalCount: number = 1,
  pageSize: number = 10,
): number {
  return Math.ceil(totalCount / pageSize)
}

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { AskAi } from "@app/assets/Icons"
import { OutlinedInput } from "@mui/material"

type AskAIProps = {
  handleOnFocus: () => void
  handleOnBlur: () => void
}

const AskAI = ({ handleOnFocus, handleOnBlur }: AskAIProps): JSX.Element => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<string>("")
  const navigate = useNavigate()

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (!inputValue.length) return

    if (event.key === "Enter") {
      event.preventDefault()
      navigate("/dashboard/ask-ai", { state: { query: inputValue } })
    }
  }

  return (
    <OutlinedInput
      sx={{
        fontSize: "20px",
        borderRadius: "60px",
        height: "66px",
        width: "100%",
      }}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onKeyDown={handleKeyDown}
      onChange={e => setInputValue(e.target.value)}
      placeholder={t("ask_ai.placeholder")}
      startAdornment={<AskAi className="mr-4" />}
    />
  )
}

export default AskAI

import { User, UsersAPI, UsersParams } from "@app/model/user"
import * as S from "@effect/schema/Schema"
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import { pipe } from "effect"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedQuery } from "../useAuthenticatedQuery"

type QueryResultData = UsersAPI
type QueryKeyType = ReturnType<typeof QueryKey.usersList>

const UserSchema: S.Schema<User> = S.Struct({
  id: S.String,
  email: S.String,
  userName: S.NullOr(S.String),
  accountType: S.String,
  active: S.Boolean,
  invitationExpired: S.NullOr(S.Boolean),
})

const UsersResponseAPISchema: S.Schema<UsersAPI> = S.Struct({
  users: S.Array(UserSchema),
  page: S.Number,
  pageSize: S.Number,
  totalCount: S.Number,
})

export const getUsersList: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey, meta }): Promise<QueryResultData> => {
  const [_, chatbotName, tenantName, params] = queryKey
  const { page, page_size, sort_by, sort_order } = params

  const paramsString = new URLSearchParams({
    page,
    page_size,
    sort_by,
    sort_order,
    tenant_name: tenantName,
    role_id: chatbotName,
  }).toString()

  const path = `${REST_URL}${API_V1}/users/?${paramsString}`

  const token = meta?.token as string

  const response = await fetch(path, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw Error("Get users fails")
  }

  const results = (await response.json()) as unknown

  return pipe(results, S.decodeUnknownSync(UsersResponseAPISchema))
}

export const useUsersQuery = (
  chatbotName: string,
  tenantName: string,
  params: UsersParams,
  options?: Omit<
    UseQueryOptions<QueryResultData, Error, QueryResultData, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<QueryResultData, Error> => {
  const queryKey = QueryKey.usersList(chatbotName, tenantName, params)

  return useAuthenticatedQuery<QueryResultData, Error, QueryKeyType>(
    queryKey,
    getUsersList,
    options,
  )
}

import { RefObject, useEffect } from "react"
import { Array, pipe } from "effect"

export const useClickOutside = <
  T extends HTMLButtonElement,
  U extends HTMLDivElement,
>(
  onClickOutside: () => void,
  refs: RefObject<T | U>[],
): void => {
  useEffect(() => {
    const handlePageClick: EventListenerOrEventListenerObject = e => {
      const isClickInside = pipe(
        refs,
        Array.some(ref => ref?.current?.contains(e.target as Node) ?? false),
      )

      if (!isClickInside) {
        onClickOutside()
      }
    }

    document.addEventListener("mousedown", handlePageClick)

    return (): void => {
      document.removeEventListener("mousedown", handlePageClick)
    }
  }, [onClickOutside, refs])
}

export default useClickOutside

import { QuestionsParams } from "@app/model/questions"

const fileList = ["files"]
const uploadFile = ["upload"]
const downloadFile = ["download"]
const questionList = ["questions"]
const question = ["question"]

export const questionById = (id: string): ["question", { id: string }] => {
  return ["question", { id }]
}

export const questionsList = (
  chatbotName: string,
  params: QuestionsParams,
): ["questions", string, QuestionsParams] => {
  return ["questions", chatbotName, params]
}

export const filesList = (chatbotName: string): ["files", string] => {
  return ["files", chatbotName]
}

export const QueryKey = {
  fileList,
  uploadFile,
  downloadFile,
  questionList,
  question,
  filesList,
  questionsList,
  questionById,
}

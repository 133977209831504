import * as S from "@effect/schema/Schema"
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import { pipe } from "effect"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedQuery } from "../useAuthenticatedQuery"

type QueryResultData = UploadedFilesResponseAPI
type QueryKeyType = ReturnType<typeof QueryKey.filesList>

export type UploadedFiles = {
  key: string
  size_bytes: number
}

export type UploadedFilesResponseAPI = {
  files: readonly UploadedFiles[]
  total: number
}

const UploadedFilesSchema: S.Schema<UploadedFiles> = S.Struct({
  key: S.String,
  size_bytes: S.Number,
})

const UploadedFilesResponseAPISchema: S.Schema<UploadedFilesResponseAPI> =
  S.Struct({
    files: S.Array(UploadedFilesSchema),
    total: S.Number,
  })

export const getFileList: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey, meta }): Promise<QueryResultData> => {
  const [_, chatbotName] = queryKey
  const token = meta?.token as string
  const filesPath = `${REST_URL}${API_V1}/files/${chatbotName}`

  const response = await fetch(filesPath, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw new Error("Get uploaded files fails")
  }

  const results = (await response.json()) as unknown

  return pipe(results, S.decodeUnknownSync(UploadedFilesResponseAPISchema))
}

export const useFilesQuery = (
  chatbotName: string,
  options?: Omit<
    UseQueryOptions<QueryResultData, Error, QueryResultData, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<QueryResultData, Error> => {
  const queryKey = QueryKey.filesList(chatbotName)
  return useAuthenticatedQuery<QueryResultData, Error, QueryKeyType>(
    queryKey,
    getFileList,
    options,
  )
}

export const formatDate = (timestamp: number, showTime?: boolean): string => {
  const date = new Date(timestamp * 1000)

  // Format the time as hh:mm (24-hour format)
  const hours = date.getHours().toString().padStart(2, "0")
  const minutes = date.getMinutes().toString().padStart(2, "0")
  const formattedTime = `${hours}:${minutes}`

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  }

  const formattedDate = date.toLocaleDateString("en-US", options)

  if (showTime) {
    return `${formattedTime} / ${formattedDate}`
  }
  return formattedDate
}

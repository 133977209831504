type ProgressProps = {
  progress: number
}

const Progress = ({ progress }: ProgressProps): JSX.Element => {
  return (
    <div
      className="bg-green-50 h-2.5 rounded-full"
      style={{ width: `${progress}%` }}
    ></div>
  )
}

export default Progress

import { Question, Rating } from "@app/model/questions"
import * as S from "@effect/schema/Schema"
import {
  QueryFunction,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"
import { pipe } from "effect"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedQuery } from "../useAuthenticatedQuery"

type QueryResultData = Question
type QueryKeyType = ReturnType<typeof QueryKey.questionById>

const RatingSchema: S.Schema<Rating> = S.Struct({
  vote: S.String,
  comment: S.String,
})

const QuestionsSchema: S.Schema<Question> = S.Struct({
  id: S.String,
  question: S.String,
  userEmail: S.String,
  userName: S.String,
  createdAt: S.Number,
  answerId: S.NullOr(S.String),
  answer: S.NullOr(S.String),
  rating: S.NullOr(RatingSchema),
})

export const getQuestion: QueryFunction<
  QueryResultData,
  QueryKeyType
> = async ({ queryKey, meta }): Promise<QueryResultData> => {
  const [_, { id }] = queryKey
  const token = meta?.token as string

  const questionPath = `${REST_URL}${API_V1}/questions/${id}`

  const response = await fetch(questionPath, {
    method: "GET",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw new Error("Failed to fetch question")
  }

  const results = (await response.json()) as unknown
  return pipe(results, S.decodeUnknownSync(QuestionsSchema))
}

export const useQuestionQuery = (
  id: string,
  options?: Omit<
    UseQueryOptions<Question, Error, Question, QueryKeyType>,
    "queryKey"
  >,
): UseQueryResult<Question, Error> => {
  const queryKey = QueryKey.questionById(id)

  return useAuthenticatedQuery<Question, Error, QueryKeyType>(
    queryKey,
    getQuestion,
    options,
  )
}

import { queryClient } from "@app/App/queryClient"
import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedMutation } from "../useAuthenticatedMutation"

type InvitationResendParams = {
  role_id: string
  tenant_name: string
  email: string
  chatbotId: string
}

type QueryResultData = { link: string }

const resendInvitation = async (
  params: InvitationResendParams,
  token: string,
): Promise<QueryResultData> => {
  const path = `${REST_URL}${API_V1}/invitation/user/resend`
  const response = await fetch(path, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...buildAuthHeader(token),
    },
    body: JSON.stringify({ ...params }),
  })

  if (!response.ok) {
    throw new Error("Failed to resend invitation link")
  }

  const results = (await response.json()) as QueryResultData
  return results
}

export const useInvitationResendQuery = (
  options?: UseMutationOptions<QueryResultData, Error, InvitationResendParams>,
): UseMutationResult<QueryResultData, Error, InvitationResendParams> => {
  return useAuthenticatedMutation<
    QueryResultData,
    Error,
    InvitationResendParams
  >(async (params, token) => resendInvitation(params, token), {
    ...options,
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: QueryKey.userList })
    },
  })
}

import { useAuth0 } from "@auth0/auth0-react"
import {
  QueryFunction,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query"

export const useAuthenticatedQuery = <
  TData,
  TError = Error,
  TQueryKey extends unknown[] = unknown[],
>(
  queryKey: TQueryKey,
  queryFn: QueryFunction<TData, TQueryKey>,
  options?: Omit<UseQueryOptions<TData, TError, TData, TQueryKey>, "queryKey">,
): UseQueryResult<TData, TError> => {
  const { getAccessTokenSilently } = useAuth0()

  const authQueryFn: QueryFunction<TData, TQueryKey> = async context => {
    const token = await getAccessTokenSilently()
    const result = await queryFn({ ...context, meta: { token } })
    return result
  }

  return useQuery<TData, TError, TData, TQueryKey>({
    queryKey,
    queryFn: authQueryFn,
    ...options,
  })
}

import { RefObject, useRef } from "react"
import useClickOutside from "@app/hooks/useClickOutside"
import LogoutButton from "@app/uiKit/LogoutButton"

type ProfileDropdownProps = {
  close: () => void
  buttonRef: RefObject<HTMLButtonElement>
}

const ProfileDropdown = ({
  close,
  buttonRef,
}: ProfileDropdownProps): JSX.Element => {
  const dropdownRef = useRef<HTMLDivElement>(null)
  useClickOutside(() => {
    close()
  }, [dropdownRef, buttonRef])

  return (
    <div
      ref={dropdownRef}
      className="absolute z-10 right-5 sm:right-10 self-start mt-28"
    >
      <LogoutButton />
    </div>
  )
}

export default ProfileDropdown

import React from "react"
import { useTranslation } from "react-i18next"
import { EvolvLogo, ISOPLogo } from "@app/assets/Icons"
import { useAuth0 } from "@auth0/auth0-react"

import ChatbotDropdown from "./ChatbotDropdown"
import ProfileHeader from "./ProfileHeader"

const Header = (): JSX.Element => {
  const { t } = useTranslation()
  const { isAuthenticated } = useAuth0()

  return (
    <div className="flex flex-row h-16 items-center justify-between px-20 pt-7 pb-3 lg:px-20">
      {isAuthenticated ? (
        <ChatbotDropdown />
      ) : (
        <div data-testid="logo-container" className="mr-6 flex items-center">
          <ISOPLogo className="mr-4" />
          <span className="font-bold px-2 text-xl">
            {t("header.profile.isop")}
          </span>
          <span className="text-xl font-medium">{t("header.profile.bot")}</span>
        </div>
      )}

      <div className="mr-6 hidden md:block">
        <EvolvLogo className="" />
      </div>

      <div className="">
        <ProfileHeader />
      </div>
    </div>
  )
}

export default Header

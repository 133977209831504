import { queryClient } from "@app/App/queryClient"
import { UseMutationOptions, UseMutationResult } from "@tanstack/react-query"

import { API_V1, buildAuthHeader, REST_URL } from "../api"
import { QueryKey } from "../queryKey"
import { useAuthenticatedMutation } from "../useAuthenticatedMutation"

export type Key = string

const deleteFile = async (
  chatbotName: string,
  key: Key,
  token: string,
): Promise<void> => {
  const filesPath = `${REST_URL}${API_V1}/files/${chatbotName}?key=${key}`
  const response = await fetch(filesPath, {
    method: "DELETE",
    headers: {
      ...buildAuthHeader(token),
    },
  })

  if (!response.ok) {
    throw new Error("File delete failed")
  }
}

export const useDeleteQuery = (
  chatbotName: string,
  options?: UseMutationOptions<void, Error, Key>,
): UseMutationResult<void, Error, Key> => {
  return useAuthenticatedMutation<void, Error, Key>(
    async (key, token) => deleteFile(chatbotName, key, token),
    {
      ...options,
      onSuccess: () => {
        void queryClient.invalidateQueries({ queryKey: QueryKey.fileList })
      },
    },
  )
}
